import React, { useMemo } from "react";

import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { IntlService, useInternationalization } from "@progress/kendo-react-intl";
import { StandardButton } from "@selas/ui-components";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { dateToString } from "../../../../utils";
import { ISession, IUser } from "../../../../utils/types/models";
import { UserDay } from "./userDay";

import { render } from "@selas/state-management";
import style from "./planning.module.scss";

dayjs.extend(isBetween);

interface UserWeekProps {
	week: SelectionRange;
	user: IUser;
	sessions: ISession[];
	redLimit: string;
	orangeLimit: string;
	openSession: (session: ISession, readOnly: boolean) => void;
}

interface ISortedWeek {
	monday: ISession[];
	tuesday: ISession[];
	wednesday: ISession[];
	thursday: ISession[];
	friday: ISession[];
	saturday: ISession[];
}

function isSessionOnDate(sessionStart: string, sessionStop: string, date: string): boolean {
	return dayjs(date).isBetween(sessionStart, sessionStop, "date", "[]");
}

const UserWeek: React.FC<UserWeekProps> = (props: UserWeekProps) => {
	const intl: IntlService = useInternationalization();
	const reduxDispatch: Dispatch = useDispatch();

	const sortedSessions: ISortedWeek = useMemo(() => {
		const newSortedSessions: ISortedWeek = { monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] };
		if (props.sessions) {
			for (const s of props.sessions) {
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(props.week.start, intl))) {
					newSortedSessions.monday.push(s);
				}
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(dayjs(props.week.start).add(1, "day").toDate(), intl))) {
					newSortedSessions.tuesday.push(s);
				}
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(dayjs(props.week.start).add(2, "day").toDate(), intl))) {
					newSortedSessions.wednesday.push(s);
				}
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(dayjs(props.week.start).add(3, "day").toDate(), intl))) {
					newSortedSessions.thursday.push(s);
				}
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(dayjs(props.week.start).add(4, "day").toDate(), intl))) {
					newSortedSessions.friday.push(s);
				}
				if (isSessionOnDate(dateToString(s.plannedStart, intl), dateToString(s.plannedStop, intl), dateToString(dayjs(props.week.start).add(5, "day").toDate(), intl))) {
					newSortedSessions.saturday.push(s);
				}
			}
		}
		return newSortedSessions;
	}, [props.sessions, intl, props.week.start]);

	return (
		<div className={"row " + style.userWeek}>
			<div className={"col-1 " + style.userName}>
				<div className="row">
					<div className="col">{props.user.firstName}</div>
				</div>
				<div className="row">
					<div className="col">{props.user.lastName}</div>
				</div>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_1"}
					date={props.week.start}
					user={props.user}
					sessions={sortedSessions.monday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_2"}
					date={dayjs(props.week.start).add(1, "day").toDate()}
					user={props.user}
					sessions={sortedSessions.tuesday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_3"}
					date={dayjs(props.week.start).add(2, "day").toDate()}
					user={props.user}
					sessions={sortedSessions.wednesday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_4"}
					date={dayjs(props.week.start).add(3, "day").toDate()}
					user={props.user}
					sessions={sortedSessions.thursday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_5"}
					date={dayjs(props.week.start).add(4, "day").toDate()}
					user={props.user}
					sessions={sortedSessions.friday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
			<div className="col" style={{ width: "16%" }}>
				<UserDay
					id={props.user.fullName + "_6"}
					date={dayjs(props.week.start).add(5, "day").toDate()}
					user={props.user}
					sessions={sortedSessions.saturday}
					orangeLimit={props.orangeLimit}
					redLimit={props.redLimit}
					openSession={props.openSession}
				/>
			</div>
		</div>
	);
};

export default UserWeek;

import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorianfr from "cldr-dates-full/main/fr/ca-gregorian.json";
import dateFieldsfr from "cldr-dates-full/main/fr/dateFields.json";
import timeZoneNamesFr from "cldr-dates-full/main/fr/timeZoneNames.json";
import caGregorian from "cldr-dates-full/main/nl/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/nl/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/nl/timeZoneNames.json";
import numbersfr from "cldr-numbers-full/main/fr/numbers.json";
import numbers from "cldr-numbers-full/main/nl/numbers.json";
import React, { useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { ILoaderState, IRenderConfig, IRenderState } from "@selas/state-management";
import { Loader, Menu, MenuItem, ProtectedRoute } from "@selas/ui-components";
import { useTranslation } from "react-i18next";
import Notifications, { NotificationsState } from "react-notification-system-redux";
import { useSelector } from "react-redux";

import { authContext, hasPermission } from "../../services/authentication";
import { ILanguageConfiguration, languages } from "../../services/translation/languages";
import { IAuthenticationState } from "../../state";
import { IApplicationState } from "../../store";
import { Permission } from "../../utils/enums";
import Catalogue from "../pages/catalogue";
import Customer from "../pages/customer";
import Help from "../pages/help";
import Home from "../pages/home";
import Masterdata from "../pages/masterdata";
import MyProfile from "../pages/myprofile";
import PersonInfo from "../pages/personInfo";
import Privacy from "../pages/privacy";
import Resources from "../pages/resources";
import Security from "../pages/security";
import Settings from "../pages/settings";
import Support from "../pages/support";
import Work from "../pages/work";

import "bootstrap-4-grid/css/grid.min.css";
import travvantLogo from "../../assets/Travvant_Logo.png";
import style from "./portal.module.scss";

load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, dateFieldsfr, timeZoneNames, timeZoneNamesFr, numbersfr, caGregorianfr);

const App: React.FC = () => {
	const { t, i18n } = useTranslation();
	const authenticationState: IAuthenticationState = useSelector((state: IApplicationState) => state.authenticationState);
	const loaderState: ILoaderState = useSelector((state: IApplicationState) => state.loaderState);
	const renderState: IRenderState = useSelector((state: IApplicationState) => state.renderState);
	const notificationState: NotificationsState = useSelector((state: IApplicationState) => state.notificationState);

	useEffect(() => {
		languages.forEach((language: ILanguageConfiguration) => {
			loadMessages(language.translations, language.code);
		});
	}, []);

	if (i18n.language !== authenticationState.currentUser.language) {
		i18n.changeLanguage(authenticationState.currentUser.language);
	}

	const workReadPermissions: Permission[] = [Permission.OpportunitiesRead, Permission.ProjectsRead, Permission.ProjectParticipantsRead, Permission.SessionsRead, Permission.GroupsRead];
	const catalogueReadPermissions: Permission[] = [Permission.CatalogueServicesRead, Permission.ServiceTypesRead, Permission.TopicsRead];
	const userInfoReadPermissions: Permission[] = [
		Permission.StatutesRead,
		Permission.DegreesRead,
		Permission.NationalitiesRead,
		Permission.ContractTypesRead,
		Permission.JobFunctionsRead,
		Permission.AbsenceReasonsRead,
		Permission.LanguagesRead,
		Permission.ExpertisesRead
	];
	const settingsReadPermissions: Permission[] = [Permission.SettingsRead];
	const securityReadPermissions: Permission[] = [Permission.UsersRead, Permission.RolesRead];
	const customerReadPermissions: Permission[] = [Permission.ContactsRead, Permission.ContactTypesRead, Permission.CompanyGroupsRead, Permission.CompaniesRead, Permission.ParticipantsRead];
	const resourcesReadPermission: Permission[] = [Permission.LocationsRead, Permission.CoachesRead, Permission.ProvincesRead, Permission.FacilitiesRead];
	const masterdataReadPermissions: Permission[] = [
		Permission.SectorsRead,
		Permission.JointCommitteesRead,
		Permission.InvoicingMomentsRead,
		Permission.NacebelCodesRead,
		Permission.DocumentTemplatesRead,
		Permission.TaskSubjectsRead,
		Permission.EmailTemplatesRead
	];
	const supportReadPermissions: Permission[] = [Permission.BackOfficeTasksRead, Permission.ErrorLogRead];

	return (
		<Router>
			<LocalizationProvider language={i18n.language}>
				<IntlProvider locale={i18n.language}>
					<div className="bootstrap-wrapper">
						{loaderState.showLoader && <Loader />}
						{renderState.components.map((config: IRenderConfig) => (
							<config.component key={config.key} {...config.props} />
						))}
						<Menu title="Travvant Outplacement" logoStyle={{ backgroundImage: "url('" + travvantLogo + "')", backgroundSize: "32px" }}>
							<MenuItem itemKey="home" iconClass="las la-home" label={t("home")} url="/" exact display="start" />
							<MenuItem itemKey="work" iconClass="las la-laptop" label={t("work")} url="/work" exact={false} isAllowed={hasPermission(...workReadPermissions)} display="start" />
							<MenuItem
								itemKey="customer"
								iconClass="las la-user-friends"
								label={t("customer")}
								url="/customer"
								exact={false}
								isAllowed={hasPermission(...customerReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="catalogue"
								iconClass="las la-shopping-cart"
								label={t("catalogue")}
								url="/catalogue"
								exact={false}
								isAllowed={hasPermission(...catalogueReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="resources"
								iconClass="las la-toolbox"
								label={t("resources")}
								url="/resources"
								exact={false}
								isAllowed={hasPermission(...resourcesReadPermission)}
								display="start"
							/>
							<MenuItem
								itemKey="personInfo"
								iconClass="las la-user-tie"
								label={t("personInfo")}
								url="/personInfo"
								exact
								isAllowed={hasPermission(...userInfoReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="masterdata"
								iconClass="las la-book-reader"
								label={t("masterdata")}
								url="/masterdata"
								exact={false}
								isAllowed={hasPermission(...masterdataReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="support"
								iconClass="las la-server"
								label={t("support")}
								url="/support"
								exact={false}
								isAllowed={hasPermission(...supportReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="security"
								iconClass="las la-shield-alt"
								label={t("security")}
								url="/security"
								exact={false}
								isAllowed={hasPermission(...securityReadPermissions)}
								display="start"
							/>
							<MenuItem
								itemKey="settings"
								iconClass="las la-sliders-h"
								label={t("settings")}
								url="/settings"
								exact
								isAllowed={hasPermission(...settingsReadPermissions)}
								display="start"
							/>
							<MenuItem itemKey="privacy" iconClass="las la-info-circle" label={t("privacy")} url="/privacy" exact display="end" />
							<MenuItem itemKey="help" iconClass="las la-question-circle" label={t("help")} url="/help" exact display="end" />
							<MenuItem itemKey="myprofile" iconClass="las la-user-circle" label={t("myProfile")} url="/myprofile" exact display="end" />
							<MenuItem
								itemKey="logout"
								iconClass="las la-sign-out-alt"
								label={t("logout")}
								display="end"
								onClick={() => authContext().logoutPopup({ mainWindowRedirectUri: window.location.origin })}
							/>
						</Menu>
						<div className={style.bodyContainer}>
							<Switch>
								<Route exact path="/" component={Home} />
								<ProtectedRoute isAllowed={hasPermission(...workReadPermissions)} path="/work" component={Work} />
								<ProtectedRoute isAllowed={hasPermission(...customerReadPermissions)} path="/customer" component={Customer} />
								<ProtectedRoute isAllowed={hasPermission(...catalogueReadPermissions)} path="/catalogue" component={Catalogue} />
								<ProtectedRoute isAllowed={hasPermission(...resourcesReadPermission)} path="/resources" component={Resources} />
								<ProtectedRoute isAllowed={hasPermission(...masterdataReadPermissions)} path="/masterdata" component={Masterdata} />
								<ProtectedRoute isAllowed={hasPermission(...securityReadPermissions)} path="/security" component={Security} />
								<ProtectedRoute isAllowed={hasPermission(...supportReadPermissions)} path="/support" component={Support} />
								<ProtectedRoute isAllowed={hasPermission(...securityReadPermissions)} path="/security" component={Security} />
								<ProtectedRoute isAllowed={hasPermission(...userInfoReadPermissions)} path="/personInfo" component={PersonInfo} />
								<ProtectedRoute isAllowed={hasPermission(...settingsReadPermissions)} path="/settings" component={Settings} />
								<Route path="/myprofile" component={MyProfile} />
								<Route path="/privacy" component={Privacy} />
								<Route path="/help" component={Help} />
							</Switch>
						</div>
						<Notifications
							notifications={notificationState}
							style={{
								Containers: {
									DefaultStyle: {
										zIndex: 10004,
										width: "auto",
										padding: "0 10px 20px"
									}
								},
								Title: {
									DefaultStyle: { fontSize: "18px" }
								},
								NotificationItem: {
									DefaultStyle: { fontSize: "17px" }
								}
							}}
						/>
					</div>
				</IntlProvider>
			</LocalizationProvider>
		</Router>
	);
};

export default App;
